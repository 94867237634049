@import '../../assets/scss/colors.scss';
@import '../../assets/scss/break-points.scss';

.not-found-inner {

  h2 {
    font-size: 35px !important;
  }

  h2,
  p {
    text-align: center;
    margin: 0 auto;
    font-family: 'Poppins';
    color: $primary !important;
  }
}

.thanks-wrapper {
  @include bp(md-max) {
    .not-found-inner {
      max-width: 650px !important;
    }
  }
}