@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/break-points.scss";

.change-plan-wrapper {
	background-color: #faf6ee;

	.navigation-header {
		justify-content: flex-start;

		.btn-wrapper {
			justify-content: flex-start;
		}
	}

	.plan-wrapper-place {
		.packages-wrapper {
			background-color: $white;
			margin: 0px 20px 0px 0px;
			border-radius: 16px;
			padding: 22px 24px;
			max-width: 400px;

			.package-title {
				@apply font-semibold text-2xl;
			}
		}

		.plan-details-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;

			background-color: $white;
			margin: 0px 10px;
			border-radius: 16px;

			&.selected-plan {
				border: 2px solid $blue-2;
			}

			.plan-title {
				width: 100%;
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
				padding: 15px;
				text-align: center;
				color: white;
				font-weight: 600;
			}

			.basic-plan,
			.silver {
				background: linear-gradient(90deg, #2c3b6a 0%, #0f2e29 100%);
			}

			.select-plan,
			.gold {
				background: linear-gradient(90deg, #ff794b 3.35%, #ff4100 97.34%);
			}

			.exclusive-plan,
			.platinum {
				background: linear-gradient(135deg, #fdeb71 0%, #f8d800 100%);
			}

			.button-silver {
				margin-left: 2.9rem !important;
				margin-right: 2.9rem !important;
			}

			.button-gold {
				margin-left: 2.8rem !important;
				margin-right: 2.8rem !important;
			}
		}
	}
}
