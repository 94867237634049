@import "../../assets/scss/colors.scss";
@import "../../assets/scss/break-points.scss";

#root {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

::selection {
	color: #fff;
	background-color: #2dbbda;
}

::moz-selection {
	color: #fff;
	background-color: #2dbbda;
}

* {
	outline: 0 !important;
}

.wrapper-container {
	max-width: 348px;
	padding: 50px 15px 80px;
	margin: 0 auto;
	width: 100%;

	@include bp(md-max) {
		max-width: 500px;
		padding: 30px 15px 50px;
	}
}

.MuiFormControlLabel-root {
	margin: 0 !important;
}

// Title text
.text-wrapper {
	h2 {
		font-weight: 600;
		font-family: "Poppins", "sans-sarif";
		font-size: 32px;
		line-height: 1.5;
		margin: 0;
		padding-bottom: 16px;

		@include bp(sm-max) {
			font-size: 28px;
			padding-bottom: 10px;
			line-height: 1.2;
		}
	}

	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5;
		font-family: "Poppins", "sans-sarif";
		margin: 0;
		padding: 0 0 16px 0;
		color: $gray_1;

		@include bp(md-max) {
			padding: 0;
		}
	}
}

// Form style
.form-wrapper {
	padding-top: 13px;

	.g-recaptcha {
		transform: scale(1.05);
		transform-origin: 0 0;
		margin-bottom: 2em;

		@include bp(sm-max) {
			transform: scale(0.95);
		}
	}

	.form-group {
		margin: 16px 0 !important;

		.form-control {
			margin: 0 !important;
			width: 100%;

			.MuiOutlinedInput-notchedOutline {
				border: 1px solid $gray;
				border-radius: 4px;

				legend {
					span {
						display: none;
					}
				}
			}

			input {
				padding: 15px 14px;
				font-size: 14px;
				color: $gray_1;

				&::placeholder {
					opacity: 1 !important;
				}

				&::-webkit-input-placeholder {
					opacity: 1 !important;
				}

				&::-moz-placeholder {
					opacity: 1 !important;
				}

				&:-moz-placeholder {
					opacity: 1 !important;
				}
			}

			label {
				font-size: 14px;
				color: $gray_1;

				&.MuiFormLabel-filled,
				&.Mui-focused {
					font-size: 14px;
					font-family: "Poppins";
					padding: 0 8px;
					background: #fff;
				}
			}

			&.textarea-input {
				.MuiOutlinedInput-input {
					height: 120px !important;
				}
			}
		}

		&.password-input {
			position: relative;

			.show-password {
				.MuiTypography-root {
					font-weight: 600;
					font-size: 12px;
					line-height: 1;
					font-family: "Poppins";
					color: $primary;
				}

				.form-checkbox {
					&:after {
						width: 12px;
						height: 12px;
					}

					&:before {
						width: 6px;
						height: 3px;
						border-width: 1px;
					}
				}
			}
		}

		&.email-decoration {
			display: flex;
			align-items: center;

			.MuiFormControl-root {
				max-width: calc(100% - 117px);
				flex: 0 0 calc(100% - 117px);
				padding-right: 10px;
			}

			.email-text {
				font-family: "Poppins";
				font-weight: 400;
				font-size: 14px;
				line-height: 1.5;
				color: $primary;
				word-break: break-all;
			}
		}

		&.error-parent {
			.form-control {
				label {
					color: $red;
				}

				.MuiOutlinedInput-notchedOutline {
					border-color: $red !important;
				}
			}

			.error-txt {
				font-size: 12px;
				color: $red;
				letter-spacing: 0.4px;
				padding-left: 14px;
				line-height: 1.3;
				display: block;

				padding-top: 5px;
			}
		}

		&.success-attempt {
			position: relative;

			.form-control {
				.MuiOutlinedInput-notchedOutline {
					padding-right: 45px;
				}
			}

			&:after {
				content: "";
				position: absolute;
				background-image: url("../../assets/Icons/ic-success.svg");
				width: 20px;
				height: 20px;
				top: 50%;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				right: 14px;
			}
		}
	}
}

// Checkbox style
.form-checkbox {
	position: relative;

	svg {
		opacity: 0;
	}

	&.Mui-checked {
		&::after {
			background-color: $secondary;
			border-color: $secondary;
		}
	}

	&:after {
		border: 1px solid $gray_4;
		border-radius: 4px;
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
	}

	&:before {
		position: absolute;
		content: "";
		width: 10px;
		height: 5px;
		border: 2px solid $white;
		opacity: 1;
		z-index: 1;
		border-top: 0;
		border-right: 0;
		transform: translate(-50%, -50%) rotate(-45deg);
		top: 50%;
		left: 50%;
		margin-top: -1px;
	}
}

// Bottom account link style
.not-have-account {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: left;
	max-width: 355px;
	padding: 0 15px;
	margin: 0 auto;
	padding-bottom: 20px;

	@include bp(md-max) {
		max-width: 500px;
		position: relative;
		margin: 15px auto 0;
		padding: 0;
	}

	span {
		font-weight: 400;
		font-size: 16px;
		font-family: "Poppins";
		color: $gray_5;

		span {
			color: $primary;
			font-weight: 700;
		}
	}
}

.password-requirement {
	p,
	ul li {
		font-family: "Poppins";
		color: $gray_1;
		font-weight: 400;
		font-size: 12px;
		line-height: 1;
		padding-bottom: 5px;
	}

	ul {
		li {
			position: relative;
			padding-left: 15px;
			line-height: 1;
			padding-bottom: 5px;

			&:after {
				position: absolute;
				content: "";
				left: 5px;
				top: 7px;
				width: 3px;
				height: 3px;
				background-color: $gray_1;
				border-radius: 50%;
			}
		}
	}
}

// Error style
.danger-login {
	background: $gray_6;
	border-radius: 8px;
	padding: 12px;
	// margin-top: 20px;

	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 1.5;
		color: $red;
		font-family: "Poppins";
		display: block;
	}
}

// Loader css
.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.5);

	&.white-bg {
		background-color: $white;
	}

	span {
		@media (max-width: 580px) {
			height: 50px;

			img {
				height: 100%;
				width: 100%;
			}
		}

		&:nth-child(1) {
			z-index: 1;
			-moz-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation: loader 4.4s infinite ease-in-out;
		}

		&:nth-child(2) {
			-moz-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation-delay: 0.3s;
			animation-delay: 0.3s;
		}

		&:nth-child(3) {
			-moz-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation-delay: 0.6s;
			animation-delay: 0.6s;
		}

		&:nth-child(4) {
			-moz-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation-delay: 0.9s;
			animation-delay: 0.9s;
		}

		&:nth-child(5) {
			-moz-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation: loader 4.4s infinite ease-in-out;
			-webkit-animation-delay: 1.2s;
			animation-delay: 1.2s;
		}
	}
}

// Loading annimation
@keyframes loader {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	75% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes loader {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	75% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.MuiInput-underline:before {
	border-bottom-style: solid !important;
}

::-webkit-scrollbar {
	width: 5px;
	background: transparent;
	scroll-padding: 0;
	height: 5px;
}

::-webkit-scrollbar-corner {
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: $primary;
	border-radius: 10px;
}

// hamburger menu

.hamburger-menu {
	position: fixed;
	left: 15px;
	top: 18px;
	width: 25px;
	height: 25px;
	transition: all 0.5s;
	z-index: 11;

	.mobile-menu-open & {
		left: 185px;
	}

	span {
		display: block;
		height: 2px;
		width: 20px;
		background-color: $primary;
		position: absolute;
		top: 50%;
		left: 0;
		transition: all 0.5s;

		.mobile-menu-open & {
			background-color: $white;
		}

		&:first-child {
			transform: translateY(-6px);

			.mobile-menu-open & {
				transform: translateY(0px) rotate(-45deg);
			}
		}

		&:last-child {
			transform: translateY(6px);

			.mobile-menu-open & {
				transform: translateY(0) rotate(45deg);
			}
		}

		&:nth-child(2) {
			width: 16px;

			.mobile-menu-open & {
				width: 0;
			}
		}
	}
}

// custom dropdown
.custom-dropdown-icon {
	label {
		font-size: 10px !important;
		text-transform: uppercase;
		color: $primary;
		opacity: 0.6;
		font-weight: 600;
		font-family: "Poppins", sans-serif;
		padding-right: 30px !important;
	}

	input,
	.MuiSelect-select {
		font-size: 14px;
		font-family: "Poppins", sans-serif;
		color: $primary;
	}

	.MuiSvgIcon-root {
		display: none;
	}

	.MuiSelect-select {
		&[aria-expanded="true"] {
			&:after {
				transform: rotate(180deg);
			}
		}

		&:after {
			position: absolute;
			content: "";
			right: 6px;
			top: 2px;
			background-image: url(../Icons/ic-down-arrow-black.svg);
			height: 14px;
			width: 14px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 14px;
			transition: all 0.5s;
		}
	}
}

.input-dropdown-field {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.MuiTooltip-tooltip {
	background-color: $primary !important;
	font-family: "Poppins", "sans-sarif" !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	padding: 8px 12px !important;
}

.MuiTooltip-arrow {
	color: $primary !important;
}

.MuiTablePagination-selectLabel
	.MuiTablePagination-select
	.MuiTablePagination-displayedRows {
	color: #0f2e29;
	font-family: "Poppins";
	font-weight: 600;
	font-size: 14px;
	font-style: normal;
}

.MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
	background-color: #0f2e29;
}

.admin-form-checkbox {
	position: relative;

	svg {
		opacity: 0;
	}

	&.Mui-checked {
		&::after {
			background-color: $primary;
			border-color: $primary;
		}
	}

	&:after {
		border: 1px solid $gray_4;
		border-radius: 4px;
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
	}

	&:before {
		position: absolute;
		content: "";
		width: 10px;
		height: 5px;
		border: 2px solid $white;
		opacity: 1;
		z-index: 1;
		border-top: 0;
		border-right: 0;
		transform: translate(-50%, -50%) rotate(-45deg);
		top: 50%;
		left: 50%;
		margin-top: -1px;
	}
}

.place-form-checkbox {
	position: relative;

	svg {
		opacity: 0;
	}

	&.Mui-checked {
		&::after {
			background-color: $blue;
			border-color: $blue;
		}
	}

	&:after {
		border: 1px solid $gray_4;
		border-radius: 4px;
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
	}

	&:before {
		position: absolute;
		content: "";
		width: 10px;
		height: 5px;
		border: 2px solid $white;
		opacity: 1;
		z-index: 1;
		border-top: 0;
		border-right: 0;
		transform: translate(-50%, -50%) rotate(-45deg);
		top: 50%;
		left: 50%;
		margin-top: -1px;
	}
}

.admin-portal-title {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-bottom: 16px;
	font-style: normal;
	font-weight: 500;
	color: $primary;
}

.modal-cancel-button {
	width: 114px !important;
	height: 48px !important;
	margin-right: 16px !important;
	border-radius: 12px !important;
	border-style: solid !important;
	font-weight: 600 !important;
	border-width: 1px !important;
	border-color: $primary !important;
	background-color: $primary !important;
}

.modal-change-button {
	width: 114px !important;
	height: 48px !important;
	border-radius: 12px !important;
	border-style: solid !important;
	font-weight: 600 !important;
	border-width: 1px !important;
	border-color: $primary !important;
	background-color: $primary !important;
}
