@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';
.watch-video-modal {
    video {
        border-radius: 16px;
    }
}

.left-menu-place {
    .menu-item {
      position: relative;
      opacity: 0.4;
    }
  
    .Mui-selected {
      .menu-item {
        opacity: 1;
      }
    }
  
    .MuiTab-root {
      text-transform: none;
      margin: 11px 0;
      padding: 0;
      font-family: 'Poppins', sans-serif !important;
  
      @include bp(lg-max) {
        min-height: 40px;
        margin: 5px 0;
  
        .text-base {
          font-size: 14px !important;
        }
      }
    }
  
    .MuiTabs-indicator {
      border-radius: 0px 2px 2px 0px;
      width: 4px;
      background: $blue-2;
      right: auto;
      left: 0;
    }
  }