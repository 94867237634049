@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/break-points.scss";

.heading-key-attributes {
    counter-reset: my-sec-counter;

    .attributes-item {
        padding: 0 0px 0px 20px;
        position: relative;
        overflow: hidden;

        @include bp(xsm-max) {
            padding-bottom: 0;
            padding-left: 15px;
            margin-bottom: 20px;
        }

        &:before {
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter) ". ";
            background-image: none;
            width: auto;
            height: auto;
            color: $blue-2;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            top: 0px;
            left: 0px;

            position: absolute;
            @include bp(xsm-max) {
                left: 0;
                top: 0px;
            }
        }
    }
}
.MuiAccordion-root {
    background-color: transparent !important;
}