@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/break-points.scss";

.badge {
	font-family: "Poppins";
	font-weight: 600 !important;
	font-size: 12px !important;
	line-height: 1 !important;
	border: 1px solid !important;
	border-radius: 27px !important;
	display: inline-block !important;
	padding: 4px 8px !important;

	&.orange-badge {
		color: #0f2e29 !important;
		background: #ffc90a !important;
		border-color: #ffc90a !important;
	}

	&.blue-badge {
		color: #ffffff !important;
		background: #0f2e29 !important;
		border-color: #0f2e29 !important;
	}

	&.green-badge {
		display: inline-flex !important;
		align-items: center;
		background: $green_1;
		border-color: transparent !important;
		color: $green;
		font-size: 10px !important;

		img {
			margin-right: 10px;
		}
	}

	&.red-badge {
		display: inline-flex !important;
		align-items: center;
		background: $gray_6;
		border-color: transparent !important;
		color: $red;
		font-size: 10px !important;

		img {
			margin-right: 10px;
		}
	}
}

.right-header-menu {
	align-items: center;

	.open-menu {
		background-color: #c9cdd5;
		border-radius: 50%;
	}
}

.dropdown_menu {
	h2 {
		font-weight: 600;
		font-size: 16px;
		line-height: 1.5;
		color: #0f2e29;
		font-family: "Poppins";
		padding-left: 24px;
	}

	.MuiPaper-root {
		background: #ffffff;
		border-radius: 8px;
		filter: drop-shadow(0px 12px 32px rgba(17, 29, 67, 0.12));

		&::before {
			width: 6px;
			height: 6px;
		}
	}

	&#notification-menu {
		.MuiPaper-root {
			border-radius: 16px;
		}
	}
}

#help-menu {
	.MuiPaper-root {
		ul {
			padding: 15px 0;
			min-width: 220px;

			@include bp(lg-max) {
				max-height: calc(100vh - 80px);
				padding: 15px 0;
				overflow: auto;
			}

			li {
				padding: 15px 20px;
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #0f2e29;
				font-family: "Poppins";
			}
		}
	}
}

#notification-menu {
	overflow: hidden;

	.MuiPaper-root {
		ul {
			padding: 24px 0 50px;
			height: 350px;
			width: 462px;

			@include bp(lg-max) {
				max-height: calc(100vh - 80px);
				width: 295px;
				padding: 15px 0 40px;
				overflow: hidden;
			}

			@include bp(xxxsm-max) {
				max-height: calc(100vh - 80px);
				width: 265px;
				padding: 15px 0 40px;
				overflow: hidden;
			}

			li {
				padding: 12px 24px;

				@include bp(lg-max) {
					padding: 10px 15px;
				}
			}
		}
	}
}

.notification-modal {
	height: 96%;
	overflow-y: scroll;

	.thats-all {
		color: #70778e;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		display: block;
		text-align: center;
		width: 190px;
		margin: auto;
		margin-top: 20px;
		margin-bottom: 24px;
	}
}

.notification-loader {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	padding-top: 110px;
}

.user-info-place {
	flex-wrap: wrap;
	align-items: center;

	&.new-notification {
		position: relative;

		&:after {
			position: absolute;
			content: "";
			width: 8px;
			height: 8px;
			background: $blue-2;
			border-radius: 50%;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			right: 14px;
		}

		.user-data p {
			font-weight: 600;
		}
	}

	.user-avtar {
		max-width: 48px;
		flex: 0 0 48px;

		.MuiAvatar-root {
			width: 48px;
			height: 48px;
			margin: 0;
		}
	}

	.user-data {
		flex: 0 0 calc(100% - 20px);
		max-width: calc(100% - 20px);
		padding: 0 0px 0 16px;

		span {
			color: #70778e;
			font-weight: 400;
			font-size: 10px;
			line-height: 1.5;
			display: block;
		}

		p {
			font-weight: 400;
			font-size: 12px;
			line-height: 1.5;
			color: #0f2e29;
			font-family: "Poppins";
			margin: 0;
			padding: 0 0 5px;
			white-space: normal;
		}
	}
}

.badge-blue {
	.MuiBadge-badge {
		background-color: $blue-2;
		top: 4px !important;
		right: 1px !important;
	}
}

.badge-orange {
	.MuiBadge-badge {
		background-color: #ff4100;
		top: 4px !important;
		right: 1px !important;
	}
}

.profile-user {
	.MuiAvatar-root {
		background-color: #0f2e29;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		font-family: "Poppins";
	}
}

#user-menu {
	&.dropdown_menu {
		.MuiPaper-root {
			min-width: 282px;
			border-radius: 16px;
			// top: 56px;
			// left: 1042px;
			// transform-origin: 208px 0px;

			ul {
				padding: 2em 2em 1em 2em !important;
				max-height: calc(100vh - 80px);
				overflow: auto;

				@include bp(lg-max) {
					padding: 15px !important;
				}
			}
		}
	}

	.profile-wrapper {
		padding-bottom: 20px;

		.MuiAvatar-root {
			width: 80px;
			height: 80px;
			background: linear-gradient(90deg, #2c3b6a 0%, #0f2e29 100%);
			margin: 0 auto 12px;
		}
	}

	.user-wrapper {
		text-align: center;
		padding-bottom: 24px;
		border-bottom: 1px solid #e7e8ec;

		a {
			font-weight: 400;
			font-size: 12px;
			line-height: 1.5;
			font-family: "Poppins";
			color: #70778e;
		}

		h2 {
			font-family: "Poppins";
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
			color: #0f2e29;
			padding: 0 0 4px;
		}
	}

	.user-contact {
		padding-top: 12px;
		padding-bottom: 16px;
		font-weight: 400;
		font-size: 10px;
		font-family: "Poppins";
		color: #70778e;
		line-height: 1.5;
		text-align: center;

		a {
			display: block;
			color: #0f2e29;
		}
	}

	.user-profile-menu {
		padding-top: 24px;

		.user-txt {
			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				font-family: "Poppins";
				color: #70778e;
				padding-bottom: 4px;
				display: block;
				text-align: left;
			}

			h2 {
				font-family: "Poppins";
				color: #0f2e29;
				font-weight: 600;
				font-size: 16px;
				line-height: 18px;
				padding: 0 0 12px;
				text-align: left;
			}
		}
	}

	li {
		padding: 13px 0;
		background-color: transparent !important;

		@include bp(lg-max) {
			padding: 10px 0;
		}

		&:hover {
			background-color: transparent;
			font-weight: 600;
		}

		.img-icon {
			min-width: 26px;
			margin-right: 6px;

			img {
				margin: 0 auto;
			}
		}

		img {
			margin-right: 15px;
		}
	}
}

// Modal css
.invite-modal {
	.MuiModal-root .modal {
		// position: relative;
		.MuiBox-root {
			box-shadow: 0px 12px 32px rgba(17, 29, 67, 0.12);
			border-radius: 16px;
			background: $white;
			border: none;
			max-width: 570px;
			width: 100%;
			max-height: 80%;
			overflow: auto;

			@include bp(sm-max) {
				padding: 15px;
				max-width: calc(100% - 30px);
			}
		}

		h2 {
			color: $primary;
			font-family: "Poppins";
			font-weight: 600;
			font-size: 24px;
			line-height: 1.5;

			@include bp(sm-max) {
				font-size: 20px;
			}
		}

		.btn-wrapper {
			background: $white;
			z-index: 2;
			padding: 0;

			.disable-btn {
				color: $white;
				background-color: $gray_9;
			}
		}
	}

	// Modal css
	// .invite-modal {
	.MuiBox-root {
		max-width: 464px;
	}

	.btn-wrapper {
		.MuiButton-root {
			&.btn {
				height: 44px;
			}
		}
	}

	// }
}

#user-menu-proptech {
	&.dropdown_menu {
		.MuiPaper-root {
			min-width: 290px;
			border-radius: 16px;

			ul {
				padding: 2em 2em 1em 2em !important;
				max-height: calc(100vh - 80px);
				overflow: auto;

				@include bp(lg-max) {
					padding: 15px !important;
				}
			}
		}
	}

	.profile-wrapper {
		padding-bottom: 20px;

		.MuiAvatar-root {
			width: 80px;
			height: 80px;
			background: linear-gradient(90deg, #2c3b6a 0%, #0f2e29 100%);
			margin: 0 auto 12px;
		}
	}

	.user-wrapper {
		text-align: center;
		padding-bottom: 24px;
		border-bottom: 1px solid #e7e8ec;

		a {
			font-weight: 400;
			font-size: 12px;
			line-height: 1.5;
			font-family: "Poppins";
			color: #70778e;
		}

		h2 {
			font-family: "Poppins";
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
			color: #0f2e29;
			padding: 0 0 4px;
		}
	}

	.user-contact {
		padding-top: 12px;
		padding-bottom: 16px;
		font-weight: 400;
		font-size: 10px;
		font-family: "Poppins";
		color: #70778e;
		line-height: 1.5;
		text-align: center;

		a {
			display: block;
			color: #0f2e29;
		}
	}

	.user-profile-menu {
		padding-top: 24px;

		.user-txt {
			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				font-family: "Poppins";
				color: #70778e;
				padding-bottom: 4px;
				display: block;
				text-align: left;
			}

			h2 {
				font-family: "Poppins";
				color: #0f2e29;
				font-weight: 600;
				font-size: 16px;
				line-height: 18px;
				padding: 0 0 12px;
				text-align: left;
			}
		}
	}

	li {
		padding: 13px 0;
		background-color: transparent !important;

		@include bp(lg-max) {
			padding: 10px 0;
		}

		&:hover {
			background-color: transparent;
			font-weight: 600;
		}

		.img-icon {
			min-width: 26px;
			margin-right: 6px;

			img {
				margin: 0 auto;
			}
		}

		img {
			margin-right: 15px;
		}
	}
}
