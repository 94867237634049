@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/break-points.scss';

.market-overview-place {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin: 0 -12px;

  @include bp(sm-max) {
    margin: 0 -10px -20px;
    width: calc(100% + 20px);
    padding-bottom: 0;
  }

  .item-market {
    flex: 0 0 33.33%;
    max-width: 33.333%;
    text-align: center;
    padding: 0 12px 20px 12px;

    @include bp(sm-max) {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0 10px;
      margin-bottom: 20px;
    }

    @include bp(xsm-max) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    &.col-6 {
      flex: 0 0 50%;
      max-width: 50%;

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .item-inner {
      border-radius: 24px;
      background-color: $gray_8;
      padding: 24px 27px;
      height: 100%;

      @include bp(xlg-max) {
        padding: 15px 20px;
      }

      @include bp(sm-max) {
        padding: 15px;
      }

      .item-inner-logos {
        max-width: 226px;
        margin: 0 auto;

        .flex {
          margin: 0 -20px;

          @include bp(desk-max) {
            margin: 0 -10px;
          }

          >span {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            // padding: 0 20px;  

            @include bp(desk-max) {
              padding: 0 10px;
            }
          }
        }
      }

      div & .item-inner-logos {
        justify-content: space-between;
        text-align: center;


        span {
          color: $primary;
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 10px;
          // opacity: 0.6;
          line-height: 1.3;

          span {
            opacity: 0.6;
          }
        }

        img {
          margin: 0 auto 4px;
          width: 48px;
          height: 48px;
        }
      }
    }

    h2 {
      padding-bottom: 24px !important;

      @include bp(sm-max) {
        padding-bottom: 15px !important;
      }
    }
  }
}

.market-growth-insights {
  border: 1px solid $gray_9;
  border-radius: 24px;
  margin: 20px 0;
  padding: 24px;
  width: 100%;

  @include bp(lg-max) {
    padding: 20px 16px;
  }

  h2 {
    padding-bottom: 12px !important;
  }

  .growth-info {
    display: flex;
    flex-wrap: wrap;

    @include bp(sm-max) {
      margin-bottom: -20px;
    }

    &.has-para {
      .growth-item {
        &:after {
          top: 4px;
        }
      }
    }

    .growth-item {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-left: 30px;
      position: relative;

      &.business-modal {
        p {
          padding-bottom: 0px !important;
        }
      }

      @include bp(sm-max) {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 20px;
      }

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 15px;
      }

      img {
        margin-bottom: 12px;
        width: 40px;
      }

      &:after {
        position: absolute;
        content: "";
        background-image: url(../../../../assets/Icons/ic-left-arrow-blue.svg);
        width: 7px;
        height: 13px;
        background-size: 100%;
        background-repeat: no-repeat;
        top: 1px;
        left: 10px;

        @include bp(xsm-max) {
          left: 0;
        }
      }

      p {
        font-size: 12px !important;
        padding: 0;
      }

      h3 {
        font-family: 'Poppins';
        color: $primary;
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .growth-info {
    &.none-mb-sm {
      @include bp(sm-max) {
        margin-bottom: 0px !important;
      }
    }
  }

  &.information-growth {
    .growth-info {
      padding-top: 8px;

      .growth-item {
        padding: 0 32px 0 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

.market-insight-tab {
  h2 {
    color: $primary;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-transform: none !important;
  }
}

.key-metrics {
  background-color: $gray_8;
  padding: 24px;
  border-radius: 24px;
  width: 100%;

  @include bp(sm-max) {
    padding: 20px 16px;
  }
}

.metrics-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px -20px;

  @include bp(lg-max) {
    margin: 0 -10px -20px;
  }

  &.align-top {
    .metrics-item {
      align-self: flex-start;
    }
  }

  .metrics-item {
    padding: 0 20px;
    max-width: 25%;
    flex: 0 0 25%;
    margin-bottom: 20px;
    align-self: flex-start;

    @include bp(lg-max) {
      padding: 0 10px;
    }

    @include bp(sm-max) {
      max-width: 50%;
      flex: 0 0 50%;
    }

    span {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      display: block;
      color: $gray_1;
      padding-bottom: 4px;

      &.capitalize-txt {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    p {
      font-weight: 600 !important;
      font-size: 25px !important;
      line-height: 1.5 !important;
      padding: 0 !important;
      color: $primary !important;

      @include bp(xlg-max) {
        font-size: 26px !important;
      }

      @include bp(sm-max) {
        font-size: 24px !important;
      }
    }
  }
}

.key-value {
  padding: 10px 0 35px;

  @include bp(sm-max) {
    padding-bottom: 10px;
  }

  span {
    display: inline-flex;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    align-items: center;
    color: $primary;
    opacity: 0.6;
    max-width: 25%;
    flex: 0 0 25%;

    @include bp(sm-max) {
      max-width: 50%;
      flex: 0 0 50%;
      margin-bottom: 20px;
    }

    @include bp(xsm-max) {
      max-width: 100%;
      flex: 0 0 100%;

    }

    img {
      width: 56px;
      height: 56px;
      margin-right: 13px;
    }
  }
}

.blue-theme {
  width: 100%;
}